<div class="side-panel-container">
    <div class="item">
        <fa-icon [icon]="homeIcon" style="font-size:28px;"></fa-icon>
    </div>
    <div class="item">
        <fa-icon [icon]="cvIcon" style="font-size:28px;"></fa-icon>
    </div>
    <div class="item">
        <fa-icon [icon]="workIcon" style="font-size:28px;"></fa-icon>
    </div>
    <div class="item">
        <fa-icon [icon]="projects" style="font-size:28px;"></fa-icon>
    </div>
</div>
