<div class="progress-container">
    <div class="card">
        <h1>James Pearce - Front end engineer</h1>
        <h2>New site in progress</h2>
        <a>For more about me please view my <a href="https://www.jamespearcedev.co.uk">existing site</a> or contact me below.</a>
        <div class="social">
            <a href="https://uk.linkedin.com/in/james-pearce-53a56b139"><fa-icon [icon]="linkedInIcon" style="font-size:32px;"></fa-icon></a>
            <a href="mailto:jamespearcedev@gmail.com"><fa-icon [icon]="emailIcon" style="font-size:33px;"></fa-icon></a>
        </div>
    </div>
</div>